<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="closeModal"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.add_emergency_contact_person') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-12 px-10">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules">
                <el-form-item :label="$t('clients.relation')" prop="relation">
                    <el-input v-model="formData.relation" />
                </el-form-item>
                <el-form-item :label="$t('clients.name')" prop="name">
                    <el-input v-model="formData.name" />
                </el-form-item>
                <el-form-item :label="$t('clients.surname')" prop="surname">
                    <el-input v-model="formData.surname" />
                </el-form-item>
                <el-form-item :label="$t('clients.telephone')" prop="telephone">
                    <el-input v-model="formData.telephone" />
                </el-form-item>
                <el-form-item :label="$t('clients.email')" prop="email">
                    <el-input v-model="formData.email" />
                </el-form-item>
                <el-form-item :label="$t('clients.street')" prop="street">
                    <el-input v-model="formData.street" />
                </el-form-item>
                <el-form-item :label="$t('clients.post_code')" prop="post_code">
                    <el-input v-model="formData.post_code" />
                </el-form-item>
                <el-form-item :label="$t('clients.city')" prop="city">
                    <el-input v-model="formData.city" />
                </el-form-item>
                <el-form-item :label="$t('clients.note')">
                    <el-input
                        v-model="formData.note"
                        type="textarea"
                        :rows="3"
                    />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.add_key')" @click="add">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './emergency_contacts.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            formData: {
                relation:  null,
                name:      null,
                surname:   null,
                telephone: null,
                email:     null,
                street:    null,
                post_code: null,
                city:      null,
                note:      null,
            },
            rules: {
                name:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                telephone: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:     [{ type: 'email', message: this.$t('common.wrong_format'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.add_emergency_contact');

            try {
                await Api.create(this.clientId, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_emergency_contact');
            }
        },

        closeModal() {
            this.clearData();
            this.$emit('close');
        },

        clearData() {
            this.$refs.form.resetFields();
            this.formData = {
                relation:  null,
                name:      null,
                surname:   null,
                telephone: null,
                email:     null,
                street:    null,
                post_code: null,
                city:      null,
                note:      null,
            };
        },
    },
};
</script>
